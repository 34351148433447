.demo-logo {
    width: 175px;
    height: inherit;
    background-size: cover;
    background-image: url('./logo4.png');
}

.user-info {
    width: 175px;
}

/* ######### Ant Design global override ######### */
/* Remove default table padding */
.ant-table-tbody{
    padding: 0 !important;
}
.ant-table-thead>tr>th{
    padding: 0 !important;
}
.ant-table-tbody>tr>td{
    padding: 0 !important;
}
.ant-space-gap-row-small{
    row-gap: 0 !important;
}
/* If <InputNumber> is inside a table, then the number should be on right side instead of left */
.ant-table .ant-input-number-handler-wrap{
    direction: rtl !important;
}
.ant-table .ant-input-number-input{
    text-align: right !important;
    padding: 4px 5px !important;
}